// components/About.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const About = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const icon = {
        //"bg-darkslate-500 text-white p-6 rounded-lg border border-darkslate-100",
        inactive: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ["rgb(44 44 44)"],
        },
        //"hover:bg-darkslate-500 text-white p-6 rounded-lg border hover:border-primary-500"
        active: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: [null, "rgb(230 0 0)"],

        }
    }

    return (
        <motion.div
            animate={isHovered ? "active" : "inactive"}
            variants={icon}
            initial="inactive"
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            transition={{ duration: 0.25 }}

        >
            <h2 className="text-3xl font-bold">About Me</h2>
            <h1 className="text-sm font-light">
                Hi, I'm <b className="font-bold">Bevan Neiberg</b>, a software engineering student currently studying at the <b className="font-bold"> Rochester Institute of Technology </b>
                with expertise in a variety of programming languages and tools.
            </h1>
            <ul className="list-disc list-inside">
                <li>Languages - JavaScript, Python, Java, C, C#, SQL</li>
                <li>Machine Learning - RL, OpenCV, PyTorch, TensorFlow </li>
                <li>Web Development - GCP, Flask, MVC, Cloudflare, Azure</li>
                <li>DevOps - Docker, Git, CI/CD</li>
                <li>Tools - VSCode, Jupyter, IntelliJ, PyCharm, Eclipse</li>
                <li>Interests - AI, ML, Web Development, Cloud Computing</li>
            </ul>
        </motion.div>
    );
};

export default About;
