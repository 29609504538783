import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router';
import Cards from '../Cards';
const PortfolioPage = ({ shouldDisableInitial }) => {
	const [animationRunning, setAnimationRunning] = React.useState(true);
	const cards = [
		{
			title: 'Rotation Advice',
			content: (
				<p>
					The award-winning game Apex Legends can be very complex for new players. As a top player, I've always been enamored with its strategic depths and pushing the boundaries of tactical play. Merging my passion for this game with a keen interest in computer vision led to the birth of <strong>Apex Rotation Advice</strong>. This project is one of many I've worked on in the realm of computer vision for video games.
				</p>
			),
			link: '/rotationadvice',
			linkText: 'Learn More: Rotation Advice',
			local: true,
		},
		{
			title: 'Apex Object Detection',
			content: <p>Apex Legends is a fast-paced battle royale game that requires both strategic planning and mechanical skill. To this end, I worked to simulate a player's ability to aim at targets. I trained a YOLO v8 model to find and autonomously attack dummies.</p>,
			link: '/objectdetection',
			linkText: 'Learn More: Object Detection',
			local: true,
		},
		{
			title: 'Breaking Turtle Graphics',
			content: <p>Anyone who has any experience in Python knows that one of the first things most students are taught is to use a library called Turtle Graphics. This library is intended to be a simple introduction to programming and, to a lesser degree, computer graphics. I decided to push the library to its absolute limits.</p>,
			link: '/breakingturtlegraphics',
			linkText: 'Learn More: Breaking Turtle Graphics',
			local: true,
		},
		{
			title: 'Risk AI',
			content: <p>Risk is a game of world domination from the 1950s. It was originally a board game but has since been adapted to the digital world. I enjoy the planning that goes into each move, and I wondered if I could train an AI agent to play the game. The result was a massive success achieved through the use of reinforcement learning.</p>,
			link: '/riskai',
			linkText: 'Learn More: Risk AI',
			local: true,
		},
	];

	const cards2 = [
		{
			title: 'Twitch.to',
			content: <p>Stable Diffusion is a technology that takes prompts from users and converts them into images. I've been using Stable Diffusion for a couple of years now, and I think it shows great promise, but it has some drawbacks. The service is designed to be run locally, and if you want to go mobile, most platforms online want money. The ones that don't are often slow, heavily restricted, or have a low resolution. I wanted to make a service that was free, fast, and high-quality. Twitch.to is the result of that desire.</p>,
			link: '/twitchto',
			linkText: 'Learn More: Twitch.to',
			local: true,
		},
		{
			title: 'Educational Technology',
			content: (
				<p>
					With skills gained from various classes at <strong>The Rochester Institute of Technology</strong>, I was able to create a variety of educational tools. These tools are designed to help students learn in a more interactive and engaging way. I've always been a firm believer that the best way to learn is to do, and these tools are a reflection of that belief.
				</p>
			),
			link: '/educationaltechnology',
			linkText: 'Learn More: Educational Technology',
			local: true,
		},
		{
			title: 'Apex Tracking',
			content: <p>Not to be confused with Apex Object Detection, Apex Tracking utilizes OCR and computer vision to deliver a suite of information useful for statistical analysis. This tool is used by some of the biggest names in the scene due to its unparalleled performance and accuracy.</p>,
			link: '/tracking',
			linkText: 'Learn More: Apex Tracking',
			local: true,
		},
		{
			title: 'Lasers',
			content: <p>From a young age, I was always transfixed by lasers. Something about light cutting through materials without ever making physical contact seemed as close to magic as anything could be. As I grew up, I was able to sate my curiosity and construct several lasers. This project shows some of my work with them.</p>,
			link: '/lasers',
			linkText: 'Learn More: Lasers',
			local: true,
		},
	];
	const location = useLocation();

	useEffect(() => {
		const handleBeforeUnload = () => {
			sessionStorage.removeItem('hasAnimationRun2');
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [location]);

	//scroll to top on page load

	const loadOrder = {
		1: <h2 className="text-3xl font-bold text-center">Portfolio</h2>,
		2: (
			<p className=" font-light">
				{' '}
				Here you can find a gallery of my work. <strong>Click</strong> on a card to view more information about the project.
			</p>
		),
		3: <Cards cards={cards2} />,
		4: <Cards cards={cards} />,
	};

	return (
		<article className="portfolio-page">
			<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
				<span style={{ overflow: animationRunning ? 'hidden' : 'visible' }} className={`loader bg-darkslate-700 text-neutral-50 flex justify-center items-center z-50  top-0 bottom-0 right-0 left-0 `}>
					<main className="main-content" style={{ overflow: animationRunning ? 'hidden' : 'visible' }}>
						<span className="content-grid">
							{Object.keys(loadOrder).map((key) => (
								<motion.div
									key={key}
									className="content"
									initial={{
										opacity: sessionStorage.getItem('hasAnimationRun') ? 1 : 0,
										y: sessionStorage.getItem('hasAnimationRun') ? 0 : 20,
									}}
									animate={{ opacity: sessionStorage.getItem('hasAnimationRun') ? 1 : 0, y: sessionStorage.getItem('hasAnimationRun') ? 0 : 20 }}
									transition={{ delay: key * 0.2 }}
									onAnimationComplete={() => {
										if (key === Object.keys(loadOrder).length.toString()) {
											setAnimationRunning(false);
											shouldDisableInitial(true);
											sessionStorage.setItem('hasAnimationRun', new Date().getTime());
										}
									}}
									style={{ overflow: animationRunning ? 'hidden' : 'visible', display: 'grid' }}
								>
									{loadOrder[key]}
								</motion.div>
							))}
						</span>
					</main>
				</span>
			</motion.article>
		</article>
	);
};

export default PortfolioPage;
