// components/About.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const TextCard = ({ text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const icon = {
        //"bg-darkslate-500 text-white p-6 rounded-lg border border-darkslate-100",
        inactive: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ["rgb(44 44 44)"],
        },
        //"hover:bg-darkslate-500 text-white p-6 rounded-lg border hover:border-primary-500"
        active: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: [null, "rgb(230 0 0)"],

        }
    }

    return (
        <motion.div
            animate={isHovered ? "active" : "inactive"}
            variants={icon}
            initial="inactive"
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            transition={{ duration: 0.25 }}

        >
            <h2 className="text-3xl font-bold">{text.title}</h2>
            <h1 className="text-sm font-light">
                {text.content}
            </h1>
        </motion.div>
    );
};

export default TextCard;
