import React, { useEffect, useState } from 'react';
import About from './About';
import Contact from './Contact';
import Cards from '../Cards';
import Clock from './Clock';
import './LandingPage.css';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const LandingPage = ({ shouldDisableInitial }) => {
	const [animationRunning, setAnimationRunning] = useState(true);
	const location = useLocation();

	useEffect(() => {
		const handleBeforeUnload = () => {
			sessionStorage.removeItem('hasAnimationRun');
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [location]);

	const cards = [
		{
			title: 'Software Engineer',
			content: 'Working at Quality Vision International, a micro metrology company serving a variety of industries from medical to aerospace.',
			link: 'https://www.qvii.com/',
			linkText: 'Quality Vision International',
			local: false,
		},
		{
			title: 'Web Developer',
			content: 'Building full stack web applications with React, Node,js, and Bootstrap. Check out my most recent project below.',
			link: 'https://twitch.to/',
			linkText: 'My Most Recent project',
			local: false,
		},
		{
			title: 'Tech Stack',
			content: 'Want to know more about my tech stack? Click the link below and check out my full stack experience.',
			link: '/techstack',
			linkText: 'Check out my tech stack',
			local: true,
		},
		{
			title: 'View My Portfolio',
			content: 'Check out my portfolio to see more of my work.',
			link: '/portfolio',
			linkText: 'View Portfolio',
			local: true,
		},
	];

	const loadOrder = {
		1: <About />,
		3: <Clock />,
		2: <Contact />,
		4: <Cards cards={cards} />,
	};

	return (
		<span style={{ overflow: 'auto' }} className="loader bg-darkslate-700 flex justify-center items-center z-50  top-0 bottom-0 right-0 left-0">
			<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
				<main className="main-content">
					<span className="content-grid">
						{Object.keys(loadOrder).map((key) => (
							<motion.div
								key={key}
								className="content"
								initial={{
									opacity: sessionStorage.getItem('hasAnimationRun') ? 1 : 0,
									y: sessionStorage.getItem('hasAnimationRun') ? 0 : 20,
								}}
								animate={{
									opacity: sessionStorage.getItem('hasAnimationRun') ? 1 : 1,
									y: sessionStorage.getItem('hasAnimationRun') ? 0 : 0,
								}}
								transition={{ delay: key * 0.2 }}
								onAnimationComplete={() => {
									if (key === Object.keys(loadOrder).length.toString()) {
										setAnimationRunning(false);
										shouldDisableInitial(true);
										sessionStorage.setItem('hasAnimationRun', new Date().getTime());
									}
								}}
								style={{ overflow: animationRunning ? 'hidden' : 'visible' }}
							>
								{loadOrder[key]}
							</motion.div>
						))}
					</span>
				</main>
			</motion.article>
		</span>
	);
};

export default LandingPage;
