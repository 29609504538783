import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import TextCard from './TextCard';
import { useNavigate } from 'react-router-dom';

const TechStack = () => {
	const [animationRunning, setAnimationRunning] = useState(true);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const handleBeforeUnload = () => {
			sessionStorage.removeItem('hasAnimationRun_techstack');
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [location]);

	const cards1 = {
		title: 'Web Development',
		content: (
			<div>
				<p>With extensive experience in full-stack web development, I specialize in building scalable and efficient web applications. My preferred stack includes:</p>
				<ul>
					<li>
						<strong>Frontend:</strong> React, JavaScript, HTML, CSS, Bootstrap
					</li>
					<li>
						<strong>Backend:</strong> Node.js, Flask, Waitress, REST
					</li>
					<li>
						<strong>Cloud Services:</strong> GCP, Azure, Cloudflare, Docker
					</li>
					<li>
						<strong>Tools:</strong> Git, ServiceNow, Jira, Agile Methodologies
					</li>
				</ul>
				<p>Examples can be seen in the portfolio part of this website</p>
				<motion.component className="block text-primary-500 hover:text-primary-500/30" style={{ margin: 'auto', textAlign: 'center', cursor: 'default' }} onClick={() => navigate('/portfolio')}>
					<hr className="border-primary-500/30" style={{ margin: '1rem' }} />
					<motion.div whileHover={{ scale: [null, 1.3, 1.2] }} transition={{ duration: 0.3 }}>
						Take me there!
					</motion.div>
				</motion.component>
			</div>
		),
	};
	const cards2 = {
		title: 'Machine Learning & AI',
		content: (
			<div>
				<p>I have expertise in Machine Learning, particularly in:</p>
				<ul>
					<li>
						<strong>Technologies:</strong> PyTorch, TensorFlow, OpenCV, YOLOv8
					</li>
					<li>
						<strong>Domains:</strong> Computer Vision, Reinforcement Learning, Transformers, OCR
					</li>
					<li>
						<strong>Tools:</strong> Jupyter, Colab, Kaggle, Scikit-learn, Pandas, Hugging Face
					</li>
				</ul>
				<p>I have used many difference kinds of ML systems, from custom Gym environments to image classifcation and object recognition.</p>
			</div>
		),
	};
	const cards3 = {
		title: 'Game Development & Computer Vision',
		content: (
			<div>
				<p>As a professional Apex Legends player, I’ve turned my gaming experience into technical projects such as:</p>
				<ul>
					<li>
						<strong>Apex Rotation Advice:</strong> A tool that offers strategic insights based on game data.
					</li>
					<li>
						<strong>Apex Object Detection:</strong> Using computer vision to improve target recognition and tracking.
					</li>
					<li>
						<strong>Apex Tracking:</strong> A tool that uses OCR and computer vision to deliver detailed game statistics and analysis.
					</li>
				</ul>
				<p>These tools have helped improve game strategies for myself and other players through advanced computer vision techniques.</p>
			</div>
		),
	};
	const cards4 = {
		title: 'DevOps & Workflow Automation',
		content: (
			<div>
				<p>During my time at Quality Vision International, I worked on optimizing internal workflows by:</p>
				<ul>
					<li>Leveraging cloud services such as GCP and Azure for deployment and automation.</li>
					<li>Implementing Git workflows to streamline development and deployment with CI/CD pipelines.</li>
					<li>Enhancing security through red team exercises to identify vulnerabilities.</li>
				</ul>
				<p>These efforts resulted in more efficient and secure operations for the company.</p>
			</div>
		),
	};
	const cards5 = {
		title: 'General Software Engineering',
		content: (
			<div>
				<p>My software engineering expertise spans across various programming languages and industries. I am proficient in:</p>
				<ul>
					<li>
						<strong>Languages:</strong> Python, Java, React, C, C#, SQL
					</li>
				</ul>
				<p>My experience includes developing educational tools, AI-powered applications, and web-based solutions for a wide range of clients.</p>
			</div>
		),
	};

	const loadOrder = {
		1: <TextCard text={cards1} />,
		2: <TextCard text={cards2} />,
		3: <TextCard text={cards3} />,
		4: <TextCard text={cards4} />,
		5: <TextCard text={cards5} />,
	};

	return (
		<span style={{ overflow: 'auto' }} className="bg-darkslate-700 text-neutral-50 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
			<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
				<main className="main-content">
					<span className="content-grid">
						{Object.keys(loadOrder).map((key) => (
							<motion.div
								key={key}
								className="content"
								initial={{
									opacity: sessionStorage.getItem('hasAnimationRun_techstack') ? 1 : 0,
									y: sessionStorage.getItem('hasAnimationRun_techstack') ? 0 : 20,
								}}
								animate={{
									opacity: sessionStorage.getItem('hasAnimationRun_techstack') ? 1 : 1,
									y: sessionStorage.getItem('hasAnimationRun_techstack') ? 0 : 0,
								}}
								transition={{ delay: key * 0.2 }}
								onAnimationComplete={() => {
									if (key === Object.keys(loadOrder).length.toString()) {
										setAnimationRunning(false);
										sessionStorage.setItem('hasAnimationRun_techstack', new Date().getTime());
									}
								}}
								style={{ overflow: animationRunning ? 'hidden' : 'visible' }}
							>
								{loadOrder[key]}
							</motion.div>
						))}
					</span>
				</main>
			</motion.article>
		</span>
	);
};

export default TechStack;
