import React, { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { motion } from 'framer-motion';

const TwitchTo = () => {
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: 'A Fully Featured AI Image Generation Service',
			description: <p>Stable Diffusion is a technology that transforms text prompts into stunning images using artificial intelligence. While I've enjoyed experimenting with it over the past couple of years, I noticed some frustrating limitations. Many online platforms charge for mobile access, and the free ones are either slow or limited in resolution and features. That inspired me to create Twitch.to—a fast, free, and high-quality image generation service that cuts out those drawbacks and offers more to the user.</p>,
			image: '/images/twitchto/example1.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'How it Works',
			title: 'Built for Speed and Quality',
			description: <p>Twitch.to is powered by my own hardware, which means there's no middleman slowing down the process. The AI model used is a state-of-the-art SDXL model, capable of generating high-resolution images up to 2048x2048 pixels. Users simply enter a prompt, and the model generates the image, all free of charge. The system uses a credit system to prevent abuse—each image costs a few credits, and users receive daily credits to keep generating images. Images are stored for 24 hours unless users create an account, giving them the option to build a personal gallery.</p>,
			image: '/images/twitchto/example2.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Generating Images',
			title: 'Seamless User Experience',
			description: <p>The image generation process is simple. Users input their prompt, select optional themes, and adjust generation settings such as sampling steps or filtering. Once they hit "Generate," the system processes the request, and if they’re first in line, they can watch a live preview of the image being created. Otherwise, they’ll see a loading bar as the image is prepared.</p>,
			image: '/images/twitchto/example3.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
		{
			id: 4,
			category: 'Technical Details',
			title: 'Crafting the Backend',
			description: (
				<div style={{ textAlign: 'left' }}>
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>Twitch.to was built using React for the frontend and Flask for the backend. The backend handles the image generation process on a system powered by an i7 13700k and an Nvidia RTX 4090, ensuring fast, reliable results.</li>
						<li>The responsive design, built with React Responsive Grid Layout, allows Twitch.to to function seamlessly across any device—whether on a PC or mobile, the experience remains smooth and intuitive.</li>
						<li>The service also offers unique features, like allowing users to choose specific poses for generated subjects, and themes applied via LoRAs (Low-Order Rank Adapters) to modify image styles. This level of customization is rare among similar services.</li>
						<li>Security is a priority as well. The system uses a reverse proxy to protect against DDoS attacks and hide the server's IP, though this means the code cannot be open-sourced.</li>
					</ul>
				</div>
			),
			image: '/images/twitchto/diagram.png',
			pointOfInterest: 20,
			backgroundColor: '#ff0000',
		},
		{
			id: 5,
			category: 'Conclusion',
			title: 'Explore It Yourself!',
			description: <p>With a focus on accessibility, speed, and customization, Twitch.to stands out as my favorite project to date. The guest mode lets you try it out without creating an account, and all generated images are yours to explore and customize.</p>,
			image: '/images/twitchto/example4.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Twitch.to</h1>
						</header>
						<CardList cardData={cardData} />
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default TwitchTo;
