// App.js
import React, { useState } from 'react';
import LandingPage from './components/landingPageParts/landingPage';
import PortfolioPage from './components/portfolioPageParts/portfolioPage';
import { useLocation, useRoutes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import RotationAdvice from './components/projects/RotationAdvice';
import ObjectDetection from './components/projects/ObjectDetection';
import BreakingTurtleGraphics from './components/projects/BreakingTurtleGraphics';
import RiskAI from './components/projects/RiskAI';
import TwitchTo from './components/projects/TwitchTo';
import Tracking from './components/projects/Tracking';
import Lasers from './components/projects/Lasers';
import EducationalTechnology from './components/projects/EducationalTechnology';
import TechStack from './components/techStack/TechStack';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
const App = () => {
    const navigate = useNavigate();
    const [shouldDisableInitialAnimation, setShouldDisableInitialAnimation] = useState(false);
    const element = useRoutes([
        {
            path: "/",
            element: <LandingPage shouldDisableInitial={setShouldDisableInitialAnimation} />,
        },
        {
            path: "/portfolio",
            element: (
                <PortfolioPage shouldDisableInitial={setShouldDisableInitialAnimation} />
            )
        }, {
            path: "/rotationadvice",
            element: <RotationAdvice />,
        },
        {
            path: "/objectdetection",
            element: <ObjectDetection />,
        },
        {
            path: "/breakingturtlegraphics",
            element: <BreakingTurtleGraphics />,
        },
        {
            path: "/riskai",
            element: <RiskAI />,
        },
        {
            path: "/twitchto",
            element: <TwitchTo />,
        },
        {
            path: "/tracking",
            element: <Tracking />,
        },
        {
            path: "/lasers",
            element: <Lasers />,
        },
        {
            path: "/educationaltechnology",
            element: <EducationalTechnology />,
        },
        {
            path: "/techstack",
            element: <TechStack />,
        },
    ]);
    const location = useLocation();

    if (!element) return null;


    return (
        <div className="App">
            <AnimatePresence mode="wait" initial={!shouldDisableInitialAnimation}>
                <FaArrowLeftLong onClick={() => navigate(-1, { state: { key: "localRedirect" } })} style={{
                    cursor: 'pointer', position: 'fixed', top: '1rem', left: '1rem', fontSize: '2rem', color: 'white', zIndex: '100', visibility: location.pathname === '/' ? 'hidden' : 'visible'
                }} />
                {React.cloneElement(element, { key: location.pathname })}
            </AnimatePresence>
            <div className='vertical-text' style={{ pointerEvents: 'none' }} onFocus={e => e.target.blur()}>
                <p>Bevan Neiberg</p>
            </div>
        </div >

    );
};

export default App;
