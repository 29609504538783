import React, { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { Container, Row, Col, Card, CardImg, CardText } from 'reactstrap';
import { motion } from 'framer-motion';

const TrackingPage = () => {
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: 'Enhancing Game Mastery: The Apex Legends Analysis Tool',
			description: <p>In the fast-paced world of competitive gaming, gaining an edge is crucial. The Apex Legends Analysis Tool was designed for this exact purpose, offering players a cutting-edge suite of analytics to dissect gameplay, understand performance, and optimize strategies. Developed in collaboration with top-tier professionals, this tool provides unparalleled insights into player performance, game mechanics, and much more. It stands as the definitive resource for anyone serious about Apex Legends analysis.</p>,
			image: '/images/apex_tracking/Damage_Tracker_Graph.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'Web Interface',
			title: 'A New Frontier in User Experience',
			description: (
				<p>
					The recent introduction of a Flask-based web interface represents a transformative leap for the tool. Users can now access the powerful analytics via a clean and intuitive interface, eliminating the need for command-line interaction and making the tool accessible to all.
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>
							<strong>Real-Time Data and Image Transmission:</strong> Thanks to real-time socket communication, data and images are transmitted instantly as they're processed, providing users with visual feedback and a deeper understanding of the gameplay dynamics as they unfold.
						</li>
						<li>
							<strong>Multi-Threading for Enhanced Performance:</strong> Leveraging multi-threading ensures that data processing is fast and efficient, critical for the complex and demanding nature of gaming analytics.
						</li>
						<li>
							<strong>User-Friendly Design:</strong> The web interface simplifies the user experience, making the advanced analytics accessible to everyone, regardless of technical proficiency.
						</li>
					</ul>
				</p>
			),
			image: '/images/apex_tracking/unknown-FCC64.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Modules',
			title: 'A Deeper Dive Into the Tools',
			description: (
				<p>
					The Apex Legends Analysis Tool comes equipped with various modules, each tailored to provide detailed insights into different facets of gameplay.
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>
							<strong>Video Decomposition Tool:</strong> This module breaks down gameplay footage into frames for a meticulous analysis of every movement and encounter, offering a clear picture of how each moment in the game unfolds.
						</li>
						<li>
							<strong>Coordinator:</strong> Serving as the orchestrator, the coordinator ensures that all modules work in harmony to deliver a seamless and comprehensive analysis.
						</li>
						<li>
							<strong>Damage Tracker:</strong> This module tracks damage dealt and received, helping players evaluate the effectiveness of their strategies and the performance of their weapons in real-time.
						</li>
					</ul>
				</p>
			),
			image: '/images/apex_tracking/unknown-8698C.jpg',
			pointOfInterest: 20,
			backgroundColor: '#ff0000',
		},
		{
			id: 4,
			category: 'Conclusion',
			title: 'Redefining Apex Legends Analytics',
			description: <p>The Apex Legends Analysis Tool is a game-changer, providing over 4000 lines of meticulously crafted code that combines advanced image processing, OCR technology, and data management. The addition of the web interface democratizes these insights, making them available to players of all skill levels. Whether you are a casual player, a pro, or a data enthusiast, this tool is designed to elevate your understanding of Apex Legends. Explore the features and dive deep into your gameplay analysis—check out the repo on github for a more detailed look at the code behind the magic.</p>,
			image: '/images/apex_tracking/Player_Health_Tracker_Graph.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
	];
	const projectDetails = {
		images: [
			{ src: '/images/apex_tracking/Damage_Tracker_Graph.jpg', caption: 'Example graph of player damage' },
			{ src: '/images/apex_tracking/Evo_Tracker_Graph.jpg', caption: 'Example graph of player Evo' },
			{ src: '/images/apex_tracking/Player_Health_Tracker_Graph.jpg', caption: 'Example graph of player health' },
			{ src: '/images/apex_tracking/Player_Ult_Tracker_Graph.jpg', caption: "Example graph of player's time till their ult is ready" },
			{ src: '/images/apex_tracking/Player_Gun_Tracker_Graph.jpg', caption: 'Example graph of player gun usage' },
			{ src: '/images/apex_tracking/unknown-D237D.jpg', caption: 'An image depicting the keypoints detected on one of the maps. Used for matching minimap to location' },
			{ src: '/images/apex_tracking/unknown-18B4C.jpg', caption: '2D heatmap of time spent in locations.' },
			{ src: '/images/apex_tracking/unknown-53D58.jpg', caption: '3D histogram of time spent in locations, smoothing applied.' },
		],
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Apex Legends Analysis Tool</h1>
						</header>
						<CardList cardData={cardData} />
						<Container fluid className="text-center py-5" style={{ margin: 'auto', maxWidth: '80%' }}>
							<h2>Image Gallery</h2>
							<Row>
								{projectDetails.images.map((image, index) => (
									<Col key={index} xs="12" sm="6" md="4" lg="3">
										<Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px', maxWidth: 'unset !important' }}>
											<CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '300px', minHeight: '300px' }} />
											<CardText className="text-center">{image.caption}</CardText>
										</Card>
									</Col>
								))}
							</Row>
						</Container>
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default TrackingPage;
