import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Clock = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

    // Split currentTime into individual digits
    const splitTime = currentTime.split('');

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const icon = {
        inactive: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ["rgb(44 44 44)"],
        },
        active: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: [null, "rgb(230 0 0)"],
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(interval);
    }, [currentTime]);

    return (
        <span>
            <motion.div
                animate={isHovered ? "active" : "inactive"}
                variants={icon}
                initial="inactive"
                onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                transition={{ duration: 0.25 }}
            >
                <section style={{ gridArea: '3 / 1 / 4 / 4' }}>
                    <h1 className="text-xl font-bold m-0">Current Time</h1>
                    <p className=" justify-center items-center">
                        {/* Animate each digit separately */}
                        {splitTime.map((digit, index) => (
                            <motion.time
                                key={index}
                                className="font-bold"
                                initial={{ opacity: 1 }}
                                animate={{ opacity: [1, 0.8, 1] }} // Subtle fade animation
                                transition={{ duration: 0.5 }} // Time for the animation
                                style={{ fontFamily: 'math', fontSize: '3em' }}
                            >
                                {digit}
                            </motion.time>
                        ))}
                    </p>
                </section>
            </motion.div>
        </span>
    );
};

export default Clock;
