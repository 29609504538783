import React, { memo, useRef } from 'react';
import { motion, useSpring, useMotionValue } from 'framer-motion';
import { Card, CardBody, CardImg, CardTitle, CardSubtitle, CardText, CardImgOverlay } from 'reactstrap';
import { useScrollConstraints } from '../utils/use-scroll-constraints.ts';
import './CardList.css';

export const Card2 = memo(
	({ keyVar, title, category, description, image, setSelected, isSelected }) => {
		const x = useMotionValue(0);
		const y = useMotionValue(0);
		const springX = useSpring(x, { stiffness: 300, damping: 30 });
		const springY = useSpring(y, { stiffness: 300, damping: 30 });

		const cardRef = useRef(null);
		const constraints = useScrollConstraints(cardRef, isSelected);

		const selectCard = () => {
			setSelected(isSelected ? false : title);
		};

		return (
			<li
				key={keyVar}
				className={`card-fixed bg-darkslate-700 card ${isSelected ? 'selected' : ''}`}
				onClick={selectCard}
				style={{
					cursor: isSelected ? 'default' : 'pointer',
					zIndex: isSelected ? 2 : 0,
					borderWidth: '0px',
				}}
			>
				<Overlay isSelected={isSelected} setSelected={setSelected} />
				<div
					className={`card-content-container ${isSelected ? 'open' : ''}`}
					style={{
						overflowY: isSelected ? 'auto' : 'hidden', // Allow scrolling when selected
						touchAction: 'pan-y', // Allow vertical scrolling on touch devices
						overflowX: 'hidden',
					}}
				>
					<motion.div ref={cardRef} className="card-content" style={!isSelected ? { x: springX, y: springY } : {}} layout drag={isSelected ? 'y' : false} dragConstraints={constraints}>
						<Card style={{ minWidth: '100%', minHeight: '100%', borderWidth: '0px' }}>
							<CardImg top width="100%" src={image} alt={title} style={{ borderRadius: '20px' }} />
							<CardBody className="bg-darkslate-700">
								<CardImgOverlay>
									<strong>
										<CardTitle>
											<h2 style={{ color: 'red' }}>{category}</h2>
										</CardTitle>
										<CardSubtitle style={{ color: 'red' }}>{title}</CardSubtitle>
									</strong>
								</CardImgOverlay>
								{isSelected && <CardText key={`${keyVar}-description`}>{description}</CardText>}
							</CardBody>
						</Card>
					</motion.div>
				</div>
			</li>
		);
	},
	(prev, next) => prev.isSelected === next.isSelected
);

const Overlay = ({ isSelected, setSelected }) => (
	<motion.div
		initial={false}
		animate={{ opacity: isSelected ? 1 : 0 }}
		transition={{ duration: 0.4 }}
		exit={{ opacity: 0 }}
		style={{
			pointerEvents: isSelected ? 'auto' : 'none',
			zIndex: 1,
		}}
		className="overlay"
	>
		<div onClick={() => setSelected(false)} />
	</motion.div>
);
