import React, { useState, useEffect } from 'react';
import { Card2 } from './Card2';
import './CardList.css';

export const CardList = ({ cardData }) => {
	const [selectedCard, setSelected] = useState(false);
	//if there is a selected card, disable scrolling on the background and enable scrolling on the card
	const body = document.querySelector('body');
	if (selectedCard) {
		body.style.overflow = 'hidden';
	} else {
		body.style.overflow = 'auto';
	}

	useEffect(() => {
		return () => {
			//cleanup function to enable scrolling on the background when the card is unselected
			const body = document.querySelector('body');
			body.style.overflow = 'auto';
		};
	}, []);

	return (
		<ul className="cards-list bg-darkslate-700">
			{cardData.map((card) => (
				<Card2 key={card.key} keyVar={card.pointOfInterest * card.id} id={card.id} title={card.title} category={card.category} description={card.description} image={card.image} pointOfInterest={card.pointOfInterest} backgroundColor={card.backgroundColor} setSelected={setSelected} isSelected={selectedCard === card.title} dark={card.dark} />
			))}
		</ul>
	);
};
