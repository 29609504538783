// components/Cards.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Cards.css';
const Cards = ({ cards }) => {
    const navigate = useNavigate();
    const [currentCard, setCurrentCard] = useState(null);


    const icon = {
        //"bg-darkslate-500 text-white p-6 rounded-lg border border-darkslate-100",
        inactive: {
            backgroundColor: 'rgb(23 23 23)',
            color: 'rgb(255 255 255)',
            padding: '1.5rem',
            borderRadius: '.5rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: ['rgb(44 44 44)'],
            flex: 1,
            flexDirection: 'col',
            justifyContent: 'space-between',
        },
        //"hover:bg-darkslate-500 text-white p-6 rounded-lg border hover:border-primary-500"
        active: {
            backgroundColor: 'rgb(23 23 23)',
            color: 'rgb(255 255 255)',
            padding: '1.5rem',
            borderRadius: '.5rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: [null, 'rgb(230 0 0)'],
            flex: 1,
            flexDirection: 'col',
            justifyContent: 'space-between',
        },
    };


    const handleLinkClick = (link) => {
        //if it's external, redirect to the link otherwise use the navigate hook
        if (link.local) {
            navigate(link.link);
        } else {
            window.open(link.link, '_blank');
        }
    }

    //if there are 4 cards, it will be a 2x2 grid, if there are 8 cards it will be a 2x4 grid
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {cards.map((card, index) => (
                <motion.div className="flex flex-col justify-between" key={index} animate={currentCard === index ? 'active' : 'inactive'} initial="inactive" variants={icon} onMouseEnter={() => setCurrentCard(index)} onMouseLeave={() => setCurrentCard(null)}>
                    <div>
                        <h3 className="text-xl font-bold">{card.title}</h3>
                        {typeof card.content === 'string' ? <p className="text-sm font-light mt-2">{card.content}</p> : card.content}
                    </div>
                    <div className="card2-footer mt-4" onFocus={e => e.target.blur()}>
                        {card.link && (
                            <motion.component className="block text-primary-500 hover:text-primary-500/30" style={{ margin: 'auto', textAlign: 'center', cursor: 'default' }} onBlur={() => setCurrentCard(null)} onClick={() => handleLinkClick(card)} >
                                <hr className="border-primary-500/30" style={{ margin: '1rem' }} />
                                <motion.div whileHover={{ scale: [null, 1.3, 1.2] }} transition={{ duration: 0.3 }}>
                                    {card.linkText || 'Learn More'}
                                </motion.div>
                            </motion.component>
                        )}
                    </div>
                </motion.div>
            ))}
        </div>
    );
};

export default Cards;
