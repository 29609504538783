import React, { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { Container, Row, Col, Card, CardImg, CardText } from 'reactstrap';
import { motion } from 'framer-motion';

const ApexAIObjectDetection = () => {
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: 'AI-Powered Object Detection in Apex Legends',
			description: <p>Apex Legends is known for its fast-paced action and dynamic gameplay. My goal with this project was to apply advanced computer vision techniques to detect and track objects within the game in real-time. Using AI, I built a system that can recognize key in-game elements, allowing for more refined analysis and interaction with the game environment. This project showcases how artificial intelligence can be integrated into gaming for enhanced data processing and insight extraction.</p>,
			image: '/images/apex_aimbot/image-43E22.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'Technological Deep Dive',
			title: 'The Technical Backbone of Object Detection',
			description: (
				<div style={{ textAlign: 'left' }}>
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>
							<strong>Object Detection with YOLO V8:</strong> I trained a YOLO V8 model on thousands of in-game dummy images to accurately identify and track specific objects within Apex Legends. This provided the foundation for precise object detection and tracking, enabling the system to process real-time data efficiently.
						</li>
						<br />
						<li>
							<strong>Smooth Object Tracking:</strong> One of the key challenges was ensuring fluid object tracking. I designed a custom algorithm that simulates natural mouse movement, ensuring smooth transitions between object detections and tracking points.
						</li>
						<br />
						<li>
							<strong>Vector-Based Object Positioning:</strong> By calculating the position of detected objects relative to the center of the screen using vectors, the system can determine the direction and magnitude of movement. This approach allowed for accurate and responsive object positioning in real-time.
						</li>
						<br />
						<li>
							<strong>Adaptive Movement with Decay Values:</strong> The detection system adjusts its speed based on the distance from the object, ensuring quick movement when distant and slowing down as the object is approached, using a decay value algorithm for smooth transitions.
						</li>
						<br />
						<li>
							<strong>Akima Spline Interpolation:</strong> To enhance prediction accuracy, Akima spline interpolation was used to predict the object's next position, ensuring the system can maintain precise tracking even during fast-paced gameplay.
						</li>
						<br />
						<li>
							<strong>Optimized Performance:</strong> The system is designed to run efficiently, with the object detection process handled by the GPU while secondary tasks are managed by the CPU. This allows the system to maintain over 20 FPS, even during intensive gameplay, while dynamically searching for new objects when none are detected within a set timeframe.
						</li>
						<br />
						<li>
							<strong>Multithreading Efficiency:</strong> The detection system operates across multiple threads, each dedicated to a specific task, ensuring smooth performance and minimizing any bottlenecks during real-time object detection and tracking.
						</li>
					</ul>
				</div>
			),
			image: '/images/apex_aimbot/train_batch0.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Gallery',
			title: 'Visualizing Object Detection',
			description: <p>Below are key visuals from various stages of development and training, showcasing the system’s evolution and its ability to accurately detect and track objects in Apex Legends. These examples illustrate the system’s learning curve and performance improvements through multiple rounds of training.</p>,
			image: '/images/apex_aimbot/train_batch2.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
	];

	const projectDetails = {
		images: [
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image-43E22.jpg`, caption: 'Good object detection of a dummy target.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/bad.jpg`, caption: 'Example of poor recognition, leading to discarded approaches.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image-CAEB8.jpg`, caption: 'Further refinement needed in this stage of the project.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/image3.jpg`, caption: 'Pose detection tested on a human target.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/labels.jpg`, caption: 'Bounding box data after training for evaluation.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch0.jpg`, caption: 'Initial training results on test images.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch1.jpg`, caption: 'Further improvements from the second training round.' },
			{ src: `${process.env.PUBLIC_URL}/images/apex_aimbot/train_batch2.jpg`, caption: 'Final round of training, processed over 16 hours on a 4090.' },
		],
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Apex Legends AI Object Detection</h1>
						</header>
						<CardList cardData={cardData} />

						{/* Video Section */}
						<Container fluid className="text-center py-5" style={{ margin: 'auto', textWrap: 'nowrap' }}>
							<Row>
								<Col>
									<Card style={{ margin: 'auto', marginBottom: '20px' }}>
										<video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/apex_aimbot/ai_view.mp4`} type="video/mp4" />
										<CardText className="text-center">AI object detection in action</CardText>
									</Card>
								</Col>
								<Col>
									<Card style={{ margin: 'auto', marginBottom: '20px' }}>
										<video width="100%" height="100%" controls src={`${process.env.PUBLIC_URL}/images/apex_aimbot/game_view.mp4`} type="video/mp4" />
										<CardText className="text-center">Gameplay showcasing detection results</CardText>
									</Card>
								</Col>
							</Row>
						</Container>

						{/* Photo Gallery Section */}
						<Container fluid className="text-center py-5" style={{ margin: 'auto', maxWidth: '80%' }}>
							<h2>Image Gallery</h2>
							<Row>
								{projectDetails.images.map((image, index) => (
									<Col key={index} xs="12" sm="6" md="4" lg="3">
										<Card style={{ height: 'auto', margin: 'auto', marginBottom: '20px', maxWidth: 'unset !important' }}>
											<CardImg top src={image.src} alt={image.caption} style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '300px', minHeight: '300px' }} />
											<CardText className="text-center">{image.caption}</CardText>
										</Card>
									</Col>
								))}
							</Row>
						</Container>
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default ApexAIObjectDetection;
