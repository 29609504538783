import React, { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { motion } from 'framer-motion';

const Lasers = () => {
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: '',
			description: (
				<p>
					When I was young, something about an intangible <i>thing</i> affecting the environment around it seemed like magic to me, and it still does today despite knowing how they work. My first foray into the world of light amplification by stimulated emission of radiation started by decidedly humble means, a cheap engraving laser from Amazon. This device, while amazing to a younger me, was hardly a sophisticated piece of equipment. It was a simple diode laser, capable of lighting matches, maybe even burning thin plastic. Despite its arguably awful build quality, and the fact that it isn't meant to be a hand- held device, it was enough of a proof of concept to get me started.
				</p>
			),
			image: '/images/lasers/burn.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'Where it Began',
			title: '80 Watts of Optical Power',
			description: <p>Fast forward a few years, and I had learned quite a bit more than my teenaged self knew. I made several successful builds, but it was time to up the ante; it was time to build a high-power diode array. The diode array was constructed from 18, 4.5- watt OSRAM 450nm diodes, arranged in a 3x6 array. As you can see above, the datasheet for the array was pivotal in the construction and I consulted it frequently. These diodes are not available to consumers, likely because of the danger they pose. The diodes are capable of outputting 80 watts of optical power, enough to handily shatter glass, and cut through thin sheet steel.</p>,
			image: '/images/lasers/diagram.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Build Details',
			title: 'Challenges and Solutions',
			description: (
				<div style={{ textAlign: 'left' }}>
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>Sadly, we live in reality, where electronics are not 100% efficient, and thus the driver board and diodes have an efficiency in the 45-50% range. The highest-end optical systems in current day can reach 70% efficiency, but we aren't dealing with laboratory- grade equipment. The system had to be able to both supply and dissipate several hundred watts of heat. The laser was initially cooled with a Thermo Electric Cooler (TEC), but this was later replaced with a 10-pound aluminum heatsink. The TEC would have been excellent if we were trying to maintain a high level of coherence and pure wavelength, but we were not, all we care about is raw power output. Removing the TEC was a major challenge as I had initially used thermal epoxy to attach it to the heatsink.</li>
						<br />
						<li>The epoxy had to be chipped away with a tungsten carbide bit, and the heatsink then had to be lapped smooth again to ensure proper thermal contact. After the heatsink was cleaned, I performed many tests to be sure it could dissipate the amount of heat coming off the array. I found its performance to be more than adequate, and used thermal epoxy to permanently attach the diode array to the heatsink. Once the array was attached, there is no going back, the diodes are now irreversibly attached to the heatsink. To change something now would require a complete rebuild of the array, and several hundred to several thousand dollars in parts.</li>
						<br />
						<li>The next move was to work on a body for the system. The weight of the heatsink severely limited what we could make the body from. My first attempt was 3D-printed parts; however, their material strength turned out to be inadequate. The 3D-printed parts were replaced with aluminum angle bar, and bar stock, which was then attached to the heatsink. Once the body was complete, the hard part begins.</li>
						<br />
						<li>Wiring. This was such a pain, it's hard to communicate with text. The diodes all had to be wired in series as you can see from the diagram, which isn't too challenging. The challenging part is then routing all the wires from the heavy-duty relay switch to the driver, and providing power to all the relays and the onboard microcontroller that regulates the temperature and triggering of the device.</li>
						<br />
					</ul>
				</div>
			),
			image: '/images/lasers/Raw_Diode.jpg',
			pointOfInterest: 20,
			backgroundColor: '#ff0000',
		},
		{
			id: 4,
			category: 'Conclusion',
			title: 'The Takeaways',
			description: <p>All the hurdles out of the way, we are left with one of the most powerful handheld lasers in the United States. As stated earlier, it makes short work of most flammable materials, and can even cut through thin sheet steel. The laser is capable of engraving glass and metal, and it was a great hands-on experience with embedded systems programming. The laser is powered by a 50C 2200mAh 11.1V lithium polymer (LiPo) battery. The 50C rating means it can output 50 times its capacity in amps, or 110 amps at once. We need this high current to power the driver board, and thus to power the diode array. I spent a decent portion of time working on power conversion and heat, but I could not be more pleased with the results.</p>,
			image: '/images/lasers/current_top.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>For the Love of Lasers</h1>
						</header>
						<CardList cardData={cardData} />
						{/* You can add a video or additional content here if needed */}
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default Lasers;
