import React, { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { motion } from 'framer-motion';

const ApexRotationAdvice = () => {
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: 'Blending Computer Vision with Apex Legends Strategy',
			description: <p>Apex Legends is more than just a game; it's a playground of strategic depth. As an avid player and fan, I was always fascinated by its mechanics. Inspired by a friend's suggestion and fueled by my interest in computer vision, I embarked on a journey to create "Apex Rotation Advice." This project represents a new chapter in my exploration of Apex's world through the lens of cutting-edge technology.</p>,
			image: '/images/apex_rotation/mapWENodes-326B6.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'The Tool',
			title: 'Safe and Efficient Rotations',
			description: <p>Apex Rotation Advice is a Python-based tool built to calculate the safest rotation paths between two locations in Apex Legends. Using advanced graph pathfinding techniques, the tool quickly computes the shortest routes based on real in-game distances, offering players a tactical edge while avoiding unnecessary engagements.</p>,
			image: '/images/apex_rotation/image-22B042.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Why Create This?',
			title: 'Combining Passion and Innovation',
			description: <p>Apex Legends has been a cornerstone of my gaming life, and I wanted to push the limits of gameplay strategy through technology. The goal behind this project was to integrate computer vision with in-game decision-making, helping top-tier squads create effective rotation strategies while minimizing risky encounters with enemies.</p>,
			image: '/images/apex_rotation/image-1C8CE.jpg',
			pointOfInterest: 20,
			backgroundColor: '#ff0000',
		},
		{
			id: 4,
			category: 'Key Features',
			title: 'Enhancing Gameplay Through Tech',
			description: (
				<div style={{ textAlign: 'left' }}>
					<ul style={{ textAlign: 'left', textWrap: 'balance' }}>
						<li>
							<strong>Universality:</strong> Designed to run on various machines, ensuring compatibility across systems.
						</li>
						<li>
							<strong>Enemy Detection:</strong> Advanced algorithms analyze potential enemy locations and plot safe routes to avoid confrontation.
						</li>
						<li>
							<strong>Improved UI:</strong> Transitioned from command-line interfaces to user-friendly, graphical displays.
						</li>
						<li>
							<strong>Consistent Image Processing:</strong> Handles various aspect ratios by normalizing them to a 1:1 format for precision.
						</li>
					</ul>
				</div>
			),
			image: '/images/apex_rotation/image-3C083.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
		{
			id: 5,
			category: 'Technical Foundation',
			title: 'Crafting the Code',
			description: <p>Clean code was the guiding principle of this project. To ensure clarity and maintainability, I employed Python's argument and return type hints throughout the codebase. Extensive testing ensured that every feature functioned as expected, maintaining the tool's reliability and performance.</p>,
			image: '/images/apex_rotation/image-02765.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 6,
			category: 'What’s Next',
			title: 'Expanding the Vision',
			description: <p>While the current version of Apex Rotation Advice already offers a host of valuable features, the future holds even more potential. The next milestone is to integrate ring movement predictions, further enhancing strategic decision-making for players.</p>,
			image: '/images/apex_rotation/image-B4D34.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 7,
			category: 'Acknowledgments',
			title: 'Teamwork Makes It Happen',
			description: <p>A huge thank you to wattson_x for map labeling, the Apex Legends Community for crucial data, and KeanuJ, whose idea was the spark that ignited this project.</p>,
			image: '/images/apex_rotation/image-EFDB8.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Apex Rotation Advice</h1>
						</header>
						<CardList cardData={cardData} />
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default ApexRotationAdvice;
