import React, { useEffect } from 'react';
import TextCard from '../techStack/TextCard';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const TwitchTo = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const card1 = {
		title: 'You caught me!',
		content: (
			<div>
				<p>This page is still under construction. Check back later for updates about my work in Educational Technology!</p>
			</div>
		),
	};
	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50 top-0 bottom-0 right-0 left-0">
				<span>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Under Construction</h1>
						</header>
						<TextCard text={card1} />
						<button onClick={() => navigate('/portfolio')} className="btn btn-primary">
							Back to Portfolio
						</button>
					</main>
				</span>
			</article>{' '}
		</motion.article>
	);
};

export default TwitchTo;
