import React from 'react';
import { useEffect } from 'react';
import { CardList } from './cardStuff/CardList';
import { motion } from 'framer-motion';
const BreakingTurtleGraphics = () => {
	// Sample data dictionary
	const cardData = [
		{
			id: 1,
			category: 'Introduction',
			title: 'A Creative Blend of Computer Vision & Art',
			description: <p>Turtle Graphics is just about the most basic you can get with making computer graphics. The system is not designed to render 3D scenes, or really anything besides simple shapes. The point of the library is you have a turtle that acts like a 'pen'. You can order it to do certain things, like going from one point to another, or picking itself up off the page.</p>,
			image: '/images/breaking_turtle/image.jpg',
			pointOfInterest: 150,
			backgroundColor: '#ff0000',
		},
		{
			id: 2,
			category: 'Thresholding',
			title: 'Converting an image into black and white',
			description: (
				<p>
					So how <strong>do</strong> you make anything interesting with such a basic tool? Typically, you don't. In my case, I was in an introductory programming course that was using turtle graphics to acclimate newcomers. I determined turtle graphics is capable of drawing lines from a given point to an endpoint with a given color. From here, it's a simple task! All we need to do is draw hundreds of thousands of lines! Ok, easier said than done. The first step to making it happen is converting images from their very complicated 3-channel color form into something a little simpler, like a binary (only pure black and pure white) image! This is what Starry Night looks like binarized. Not so pretty.
				</p>
			),
			image: '/images/breaking_turtle/threshheld.jpg',
			pointOfInterest: 220,
			backgroundColor: '#ff0000',
		},
		{
			id: 3,
			category: 'Computing Color',
			title: 'What is an image without color?',
			description: (
				<p>
					This sounds like a pretty <strong>trivial</strong> task, but let me assure you it's anything but! To figure out what color any given drawn line should be, we create polygons around all the binarized regions and calculate the average color inside those polygons! This allows us to correctly figure out what each line should look like color-wise, but we still have thousands of lines to draw. If only there was a way to cut down on the expensive pen up, pen down commands...
				</p>
			),
			image: '/images/breaking_turtle/computed.jpg', // Place this image in your 'public/images' folder
			pointOfInterest: 20,
			backgroundColor: '#ff0000',
		},
		{
			id: 4,
			category: 'Drawing onto the page',
			title: 'Optimization and the final product',
			description: (
				<p>
					Ok, so now we have <strong>lines</strong> and <strong>color</strong>, time to draw right! Not quite yet. You see, turtle graphics is deeply not designed to do so many operations so quickly and begins to lose performance massively when provided anything north of 100,000 lines. We have to perform some optimizations in order to be able to draw in any realistic amount of time. This optimization comes in the form of seeing which lines are close together, or have close starting/ending points and similar colors, then combining them into one line and drawing that instead! With this technique, we drop the line count by an order of magnitude, from 300,000 to 30,000 without major quality degradation. The final product is above! Pretty neat to push what amounts to a kid's toy to
					the absolute limits.
				</p>
			),
			image: '/images/breaking_turtle/drawn.jpg',
			pointOfInterest: 430,
			backgroundColor: '#ff0000',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.article initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5 } }} exit={{ opacity: 0, transition: { duration: 0.25 } }}>
			<article className="container bg-darkslate-700 flex justify-center items-center z-50  top-0 bottom-0 right-0 left-0 ">
				<span className={` `}>
					<main>
						<header style={{ color: 'red' }}>
							<h1>Breaking Turtle Graphics</h1>
						</header>
						<CardList cardData={cardData} />
						<video width="100%" height="100%" controls autoPlay loop src={`${process.env.PUBLIC_URL}/images/breaking_turtle/drawing turtle.mp4`} type="video/mp4" />
					</main>
				</span>
			</article>
		</motion.article>
	);
};

export default BreakingTurtleGraphics;
